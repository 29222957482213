import type { woDatType, anyObject } from "./workoutonomyTypes";
import { useState, useEffect } from "react";
import { Row, Col, Typography, Divider } from "antd";
import CardTopStats from "./components/CardTopStats";
import { sampleUserData } from "./examples/sampleWorkoutonomyData";
import CompletedWorkouts from "./features/CompletedWorkouts/CompletedWorkouts";
import CurrentWorkout from "./features/CurrentWorkout/CurrentWorkout";

const { Text, Title, Paragraph } = Typography;
function WorkoutonomyIndex() {
    const [woDat, setWoDat] = useState<woDatType | null>(null);
    useEffect(() => {
        setWoDat(sampleUserData)
    }, [])
    return (
        <div className="WorkoutonomyDiv">
            <Title level={1} style={{ marginBottom: 0, }} className="workoutonomyGradient">Workoutonomy</Title>
            <Paragraph style={{ color: "#BCC1C6", }} >Your guide to a <strong className="workoutonomyFlamingo">healthier</strong> you</Paragraph>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {woDat ? <CardTopStats woDat={woDat} /> : "No data available"}
                </Col>
            </Row>
            <Title level={5} style={{ color: "#BCC1C6", }} >Ongoing</Title>
            <CurrentWorkout />
            <Title level={5} style={{ color: "#BCC1C6", }} >Planned</Title>
            <Title level={5} style={{ color: "#BCC1C6", }} >Completed</Title>
            <CompletedWorkouts />

        </div>
    )
}

export default WorkoutonomyIndex;
