import rupiah from "./rupiah";
function buildWaMsg(name: string, itemList: { name: string, priceBeforePromo: number | null, priceAfterPromo: number }[], totalBeforePromo: number, totalAfterPromo: number, linkToApp: string) {
    return `*${name}*
--Items Bought (${itemList.length})--
${itemList.map(el => `${el.name} : ~${rupiah(el.priceBeforePromo || 0)}~ ${rupiah(el.priceAfterPromo)}`).join("\n")}
--
You saved :~${rupiah(totalBeforePromo - totalAfterPromo)} (${100 - Math.round((totalAfterPromo / totalBeforePromo) * 100)}% discount)~
*Total to Pay* : ~${rupiah(totalBeforePromo)}~ ${rupiah(totalAfterPromo)}

--Transaction Link--

${linkToApp}
`;
}
export default buildWaMsg;