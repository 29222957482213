import { Row, Col, Typography, Card, Button, Badge, } from "antd";
import { RightOutlined } from "@ant-design/icons";
import AvatarSpender from "../../../../components/AvatarSpender";
import styleCenterVertHorDiv from "../../utils/styleCenterVertHorDiv";

const { Text, Title } = Typography;
function CardWorkoutPlan({ item }: { item: any }) {
    return (<Card style={{ margin: 12, marginTop: 0, }}>
        <Row gutter={16}>
            <Col span={4} style={styleCenterVertHorDiv} >
                {/* <Avatar size={40} shape="square">???</Avatar> */}
                <AvatarSpender spenderName={item.name} size={40} shape="square" />
            </Col>

            <Col span={18} >
                <Title level={4} style={{ margin: 0 }}>{item.name}</Title>
                <Text type="secondary">{item.shortInstructions}</Text>
                <br />
                <Text type="success"><small><Badge status="success" /> {item.dateCompleted}</small></Text>
            </Col>
            {/* <Typography.Link>View More <RightOutlined /></Typography.Link> */}
            {/* <RightOutlined style={{ float: "right" }} /> */}
            <Col span={2}

                // style={{ display: 'flex', justifyContent: 'flex-end' }}
                style={styleCenterVertHorDiv}
            >
                {/* <RightOutlined style={{ color: "#1677ff" }} /> */}
                <Button type="link" icon={<RightOutlined />} />
                {/* <Button style={{
                                // display: "inline-flex", alignItems: "center",
                                height: 0,
                            }} type="link" icon={<RightOutlined style={{ color: "#1677ff" }} />} /> */}
            </Col>
        </Row>

    </Card >)
}
export default CardWorkoutPlan;