import { Avatar } from "antd";
import type { AvatarProps } from "antd";
import colorHasher from "../utils/colorHasher";
function AvatarSpender({ spenderName, onClick, size, shape, style, }: {
    spenderName: string, onClick?: AvatarProps["onClick"], size?: AvatarProps["size"], shape?: AvatarProps["shape"], style?: AvatarProps["style"]
}) {
    return <Avatar onClick={onClick} shape={shape} size={size}
        style={{ ...style, backgroundColor: spenderName ? colorHasher.hex(spenderName) : undefined }}
    >{spenderName ? spenderName.charAt(0) : null}</Avatar>;
}
export default AvatarSpender;