import { useState, useEffect } from "react";
import { findLastIndex } from "lodash";
import { List, Typography, Button, message, Tooltip, Divider, } from "antd";
import { PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import AvatarSpender from "../../../../components/AvatarSpender";
import { sampleCurrentWorkoutData } from "../../examples/sampleWorkoutonomyData";
import { currentWoListType, } from "../../workoutonomyTypes";
import CardExercise from "../CardExercise/CardExercise";
import ModalExerciseBuilder from "../ModalExerciseBuilder";
const { Text, Title, } = Typography;



function CurrentWorkout() {
    const [dataSource, setDataSource] = useState<currentWoListType>([]);
    const [myCounter, setMyCounter] = useState(0);
    const [showExerciseBuilder, setShowExerciseBuilder] = useState(false);
    useEffect(() => {
        console.log("Useffect");
        setDataSource(sampleCurrentWorkoutData);
    }, []);

    const onAddExercise = () => {
        setShowExerciseBuilder(true);
    }

    const onAddClick = (ds: currentWoListType, i: number) => {
        const temp = [...ds];
        const firstIndex = temp[i].setsPlanned.findIndex(el => !el.completed);
        if (firstIndex >= 0) {
            temp[i].setsPlanned[firstIndex].completed = true;
        } else {
            temp[i].setsPlanned.push({ reps: temp[i].repDefaultCount, completed: true, essential: false, });
        }
        setDataSource(temp);
        console.log("plus", temp[i]);
        setMyCounter(myCounter + 1);
    }
    const onMinusClick = (ds: currentWoListType, currIdx: number) => {
        const temp = [...ds];
        const setsPlanned = temp[currIdx].setsPlanned;
        // setsPlanned.pop();
        const lastIndex = findLastIndex(setsPlanned, (el) => !el.essential);
        if (lastIndex >= 0) {
            setsPlanned.pop()
        } else {
            // if essential
            const lastIndexEssential = findLastIndex(setsPlanned, (el) => el.essential && el.completed);
            if (lastIndexEssential >= 0) {
                setsPlanned[lastIndexEssential].completed = false;
            }
        }

        setDataSource(temp);
        console.log("minus", temp);
    }

    return (<>
        <ModalExerciseBuilder open={showExerciseBuilder} triggerModal={() => {
            setShowExerciseBuilder(!showExerciseBuilder);
        }} />
        <List
            // grid={{gutter:16,xs:24,md:24}}
            dataSource={dataSource}
            renderItem={(item, i) => {
                if (item.type === "divider") return <Divider style={{ color: "rgba(188, 193, 198, 1)", borderColor: "rgba(188, 193, 198, 0.25)" }} >{item.name}</Divider>
                return (<CardExercise item={item} onAddClick={() => onAddClick(dataSource, i)} onMinusClick={() => onMinusClick(dataSource, i)} />)
            }} />
        {/* <Text style={{ color: "#bcc1c6" }}><PlusCircleOutlined /> Add Workout</Text> */}
        <Tooltip title="Add exercise" placement="right">
            <Button onClick={onAddExercise} shape="round" type="dashed" style={{ color: "#bcc1c6", backgroundColor: "rgba(188, 193, 198, 0)", borderColor: "rgba(188, 193, 198, 0.25)" }} icon={<PlusCircleOutlined />}>Add Exercise</Button>
        </Tooltip>
        <Divider style={{ color: "rgba(188, 193, 198, 1)", borderColor: "rgba(188, 193, 198, 0.25)" }} />
    </>)

}
export default CurrentWorkout;