import { useState, } from "react";
import { Grid, Modal, Space, Table, message, Button, Form, Input, InputNumber, Select, Card, Switch, Tag, DatePicker, Checkbox, Divider, } from "antd";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import type { FormInstance } from "antd";

const neatLabelCol = { span: 6 };

function ExerciseSection({ form }: { form: FormInstance }) {
    return (<>
        <Divider>Select a Template</Divider>
        <Form.Item>
            <Select options={[{ label: `Pull Ups (${"#9jajf"})`, value: "Pull Ups" }]} />
        </Form.Item>
        <Divider>Exercise Builder</Divider>
        <Form.Item name="name" label="Exercise Name" labelCol={neatLabelCol}  >
            <Input placeholder="Exercise Name" />
        </Form.Item>
        <Form.Item name="shortInstructions" label="Short Instructions" labelCol={neatLabelCol} >
            <Input.TextArea placeholder="short instructions" />
        </Form.Item>
        <Form.Item name="repDefaultCount" label="Default rep count" labelCol={neatLabelCol} >
            <InputNumber placeholder="reps" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="tags" label="Tags" labelCol={neatLabelCol} >
            <Select placeholder="select tags"
                mode="multiple"
                options={[
                    { label: <Tag color="pink">Upper Body</Tag>, value: "Upper Body" },
                    { label: <Tag color="cyan">Lower Body</Tag>, value: "Lower Body" },
                ]}
                onChange={(value: string[]) => {
                    if (value.length > 1) message.error("not allowed");
                }}
            />
        </Form.Item>
        {/* <Card title="Sets" size="small"> */}
        <Divider>Sets</Divider>
        <Form.List name="items" >
            {(fields, { add, remove }) => (
                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                    {fields.map((field) => (
                        <Card
                            size="small"
                            title={`Set ${field.name + 1}`}
                            key={field.key}
                            extra={
                                <CloseOutlined
                                    onClick={() => {
                                        remove(field.name);
                                    }}
                                />
                            }
                        >
                            <Space>
                                <Form.Item name={[field.name, "reps"]}
                                    initialValue={form.getFieldValue("repDefaultCount")}
                                    label="Reps"
                                >
                                    <InputNumber placeholder="reps" style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item name={[field.name, "essential"]}
                                    label="Essential"
                                >
                                    <Switch />
                                </Form.Item>
                                <Form.Item name={[field.name, "completed"]}
                                    label="Completed"
                                >
                                    <Switch />
                                </Form.Item>

                            </Space>
                        </Card>
                    ))}

                    <Button type="dashed" onClick={() => add()} block>
                        + Add Set
                    </Button>
                </div>
            )}
        </Form.List>
        {/* </Card> */}
        <Divider />
        <Divider>Others</Divider>
        <Form.Item name="dateCompleted" label="Date completed" labelCol={neatLabelCol} >
            <DatePicker style={{ width: "100%" }} showTime onChange={(
                value: DatePickerProps['value'] | RangePickerProps['value'],
                dateString: [string, string] | string,
            ) => {
                console.log('Selected Time: ', value);
                console.log('Formatted Selected Time: ', dateString);
            }} onOk={(value: DatePickerProps['value'] | RangePickerProps['value']) => {
                console.log('onOk: ', value);
            }} />
        </Form.Item>
        <Divider />
    </>)
}

function ModalExerciseBuilder({ open, triggerModal, }: { open: boolean, triggerModal: any, }) {
    const [form] = Form.useForm();
    const [exerciseType, setExerciseType] = useState("");

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    return (<Modal centered open={open} onCancel={triggerModal} footer={false}>
        <Form onFinish={onFinish} form={form}>
            <Form.Item name="type" label="Item Type" labelCol={neatLabelCol}>
                <Select placeholder="type of item"
                    options={[
                        { label: "💪 Exercise", value: "exercise" },
                        { label: "--Divider--", value: "divider" },
                    ]}
                    onChange={(v) => {
                        setExerciseType(v);
                    }}
                />
            </Form.Item>

            {form.getFieldValue("type") === "exercise" && <ExerciseSection form={form} />}


            <Form.Item>
                <Button style={{ marginTop: 16 }} block type="primary" htmlType="submit" icon={<PlusCircleOutlined />}>Create</Button>
            </Form.Item>


        </Form>
        {/* {JSON.stringify({
            type: "exercise",
            name: "Lunges",
            shortInstructions: "Lunges 6 reps, 4 sets,",
            repDefaultCount: 6,
            tag: {
                title: "lower", color: "cyan",
            },
            setsPlanned: [
                { reps: 6, completed: false, essential: true, },
                { reps: 6, completed: false, essential: true, },
                { reps: 6, completed: false, essential: true, },
                { reps: 6, completed: false, essential: true, }],
            dateCompleted: "28 May 2023, 10 pm"
        })} */}
    </Modal>)
}
export default ModalExerciseBuilder;