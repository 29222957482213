export const sampleUserData = {
    lastWorkoutUnix: 1695133113,//7am GMT UNIX
}
export const sampleWorkoutHistoryData = [
    {
        name: "Interval Swim",
        shortInstructions: "MS: 4x 200m (1,600 total)",
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        name: "Easy Bike",
        shortInstructions: "50 min,",
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        name: "Interval Run",
        shortInstructions: "40 min, 188bpm x 5, fast,",
        dateCompleted: "28 May 2023, 10 pm"
    },
];

export const sampleWorkoutItemData = {
    type: "exercise",
    name: "Squat",
    shortInstructions: "Squat 8 reps, 4 sets", //optional
    tag: {
        title: "lower", color: "cyan",
    },
    repDefaultCount: 4,
    setsPlanned: [{ reps: 4, completed: false, essential: true, },],
    dateCompleted: "28 May 2023, 10 pm"
};

export const sampleCurrentWorkoutData: typeof sampleWorkoutItemData[] = [
    // @ts-ignore
    { type: "divider", name: "Upper Body Exercises" },
    {
        type: "exercise",
        name: "Shoulder press 12x",
        shortInstructions: "",
        repDefaultCount: 12,
        tag: {
            title: "upper", color: "pink",
        },
        setsPlanned: [{ reps: 12, completed: false, essential: true, }, { reps: 12, completed: false, essential: true, }, { reps: 12, completed: false, essential: true, }, { reps: 12, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        type: "exercise",
        name: "Lateral raises 6x,",
        shortInstructions: "",
        repDefaultCount: 6,
        tag: {
            title: "upper", color: "pink",
        },
        setsPlanned: [{ reps: 6, completed: false, essential: true, }, { reps: 6, completed: false, essential: true, }, { reps: 6, completed: false, essential: true, }, { reps: 6, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        type: "exercise",
        name: "Tricep extensions, 5x",
        shortInstructions: "",
        repDefaultCount: 5,
        tag: {
            title: "upper", color: "pink",
        },
        setsPlanned: [{ reps: 5, completed: false, essential: true, }, { reps: 5, completed: false, essential: true, }, { reps: 5, completed: false, essential: true, }, { reps: 5, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        type: "exercise",
        name: "Pushups ",
        shortInstructions: "Just follow the app",
        repDefaultCount: 1,
        tag: {
            title: "upper", color: "pink",
        },
        setsPlanned: [{ reps: 1, completed: false, essential: true, }, { reps: 1, completed: false, essential: true, }, { reps: 1, completed: false, essential: true, }, { reps: 1, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    // @ts-ignore
    { type: "divider", name: "Lower Body Exercises" },
    {
        type: "exercise",
        name: "Squat",
        shortInstructions: "Squat 8 reps, 4 sets",
        repDefaultCount: 8,
        tag: {
            title: "lower", color: "cyan",
        },
        setsPlanned: [{ reps: 8, completed: false, essential: true, }, { reps: 8, completed: false, essential: true, }, { reps: 8, completed: false, essential: true, }, { reps: 8, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        type: "exercise",
        name: "Lunges",
        shortInstructions: "Lunges 6 reps, 4 sets,",
        repDefaultCount: 6,
        tag: {
            title: "lower", color: "cyan",
        },
        setsPlanned: [{ reps: 6, completed: false, essential: true, }, { reps: 6, completed: false, essential: true, }, { reps: 6, completed: false, essential: true, }, { reps: 6, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        type: "exercise",
        name: "Deadlift",
        shortInstructions: "Deadlift 12 reps, 4 sets",
        repDefaultCount: 12,
        tag: {
            title: "lower", color: "cyan",
        },
        setsPlanned: [{ reps: 12, completed: false, essential: true, }, { reps: 12, completed: false, essential: true, }, { reps: 12, completed: false, essential: true, }, { reps: 12, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
    {
        type: "exercise",
        name: "Calf Raise",
        shortInstructions: "Calf Raise 14 reps, 4 sets",
        repDefaultCount: 14,
        tag: {
            title: "lower", color: "cyan",
        },
        setsPlanned: [{ reps: 14, completed: false, essential: true, }, { reps: 14, completed: false, essential: true, }, { reps: 14, completed: false, essential: true, }, { reps: 14, completed: false, essential: true, }],
        dateCompleted: "28 May 2023, 10 pm"
    },
]
