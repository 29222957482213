import type { listOfBills } from '../../../ttdTypes';
import { Card, Space, Tag, Typography, Divider, Grid, Row, Col, List, } from "antd";
import { useLocation } from 'react-router-dom';
import AvatarSpender from "../../../components/AvatarSpender";
import { DeleteOutlined, PlusCircleOutlined, WhatsAppOutlined, CheckOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import rupiah from "../../../utils/rupiah";
import buildWaMsg from '../../../utils/buildWaMsg';
const { Text } = Typography;



function processDataBySpender(bills: listOfBills) {
    const processed = bills.reduce<{ [key: string]: { name: string, totalBeforePromo: number, totalAfterPromo: number, items: { name: string, who: string, priceBeforePromo: number | null, priceAfterPromo: number }[], } }>((acc, el) => {
        if (!el.who) return acc;
        if (!(el.who in acc)) acc[el.who] = { name: el.who, totalBeforePromo: 0, totalAfterPromo: 0, items: [], };
        acc[el.who].totalBeforePromo += el.priceBeforePromo || 0;
        acc[el.who].totalAfterPromo += el.priceAfterPromo;
        acc[el.who].items.push({ name: el.item, who: el.who, priceBeforePromo: el.priceBeforePromo, priceAfterPromo: el.priceAfterPromo });
        return acc;
    }, {});
    return processed;
}
function TagihanSummaryBySpender({ billName, bills, screens, linkToApp }: { billName: string, bills: listOfBills, screens?: ReturnType<typeof Grid.useBreakpoint>, linkToApp: string }) {
    const processed = processDataBySpender(bills);

    const spenders = Object.values(processed).reduce<any[]>((acc, el, i) => {
        const curr = <Typography.Text key={`spender${i}`} copyable={{
            icon: <WhatsAppOutlined />,
            text: buildWaMsg(`Bill${billName ? ` [${billName}]` : ""} - ${el.name} (${dayjs().format("DD-MM-YYYY HH:mm:ss")})`, el.items, el.totalBeforePromo, el.totalAfterPromo, linkToApp),
            tooltips: ["Copy Whatsapp message", "Copied Whatsapp message"],
        }}>
            <AvatarSpender spenderName={el.name} /> <strong>{el.name}</strong> <Text type="secondary">pays</Text> {rupiah(el.totalAfterPromo)}</Typography.Text>
        acc.push(curr);
        return acc;
    }, []);
    return <Space
        // wrap={screens && screens.md ? true : false}
        // direction={screens && screens.md ? "horizontal" : "vertical"}
        direction="vertical"
        wrap
        split={screens && screens.md ? <Divider type="vertical" /> : undefined}
    >{spenders}</Space>
}


function MobileTagihanSummaryBySpender({ billName, bills, screens, linkToApp }: { billName: string, bills: listOfBills, screens?: ReturnType<typeof Grid.useBreakpoint>, linkToApp: string }) {
    const processed = processDataBySpender(bills);

    const spenders = Object.values(processed).reduce<any[]>((acc, el, i) => {
        const curr = (<Col span={24}>
            <Card>
                <Typography.Text key={`spender${i}`} copyable={{
                    icon: <WhatsAppOutlined />,
                    text: buildWaMsg(`Bill${billName ? ` [${billName}]` : ""} - ${el.name} (${dayjs().format("DD-MM-YYYY HH:mm:ss")})`, el.items, el.totalBeforePromo, el.totalAfterPromo, linkToApp),
                    tooltips: ["Copy Whatsapp message", "Copied Whatsapp message"],
                }}>
                    <Tag style={{ paddingTop: 4, paddingBottom: 4 }} icon={<AvatarSpender size="small" spenderName={el.name} />}> <strong>{el.name}</strong></Tag><Text type="secondary">pays</Text> {rupiah(el.totalAfterPromo)}
                </Typography.Text>
            </Card>
        </Col>)
        acc.push(curr);
        return acc;
    }, []);
    return <Row gutter={[16, 16]}>{spenders}</Row>
    // return <Space
    //     direction="vertical"
    //     wrap
    // // wrap={screens && screens.md ? true : false}
    // // direction={screens && screens.md ? "horizontal" : "vertical"}
    // // split={screens && screens.md ? <Divider type="vertical" /> : undefined}
    // >{spenders}</Space>
}


function CardBillSummary({ bills, billName, totalAfterPromo, totalBeforePromo, }: { bills: listOfBills, billName?: string, totalAfterPromo: number, totalBeforePromo: number, }) {
    const screens = Grid.useBreakpoint();
    const location = useLocation();
    const linkToApp = `${window.location.origin}${location.search}`;
    const processed = processDataBySpender(bills);
    return (<Card bodyStyle={{
        padding: 8,
        // margin: 8,
        paddingTop: 16,
        paddingBottom: 0,
    }}>
        {/* <AvatarSpender spenderName="John" /> */}
        {/* <MobileTagihanSummaryBySpender billName={billName || ""} bills={bills} screens={screens} linkToApp={linkToApp} /> */}
        <List
            grid={{ column: 1 }}
            dataSource={
                // [{ title: 'Title 1', }, { title: 'Title 2', }, { title: 'Title 3', }, { title: 'Title 4', },]
                Object.values(processed)
            }
            renderItem={(el, index) => (
                <List.Item>
                    {/* <Card title={el.name}>Card content</Card> */}
                    <Card bodyStyle={{
                        padding: 8, paddingLeft: 12,
                    }}>
                        <Row>
                            <Col>
                                <AvatarSpender spenderName={el.name} />
                            </Col>
                            <Col offset={1}>
                                <Typography.Text key={`spender${index}`}
                                    copyable={{
                                        icon: <WhatsAppOutlined />,
                                        text: buildWaMsg(`Bill${billName ? ` [${billName}]` : ""} - ${el.name} (${dayjs().format("DD-MM-YYYY HH:mm:ss")})`, el.items, el.totalBeforePromo, el.totalAfterPromo, linkToApp),
                                        tooltips: ["Copy Whatsapp message", "Copied Whatsapp message"],
                                    }}
                                >
                                    <strong>{el.name}</strong>
                                    <br /> <Text type="secondary">pays</Text> {rupiah(el.totalAfterPromo)}
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Card>
                </List.Item>)
            } />
    </Card >)
}
export default CardBillSummary;