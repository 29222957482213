import { Row, Col, Typography, Card, Button, Badge, Space, Tooltip, message, Tag, } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, RightOutlined } from "@ant-design/icons";
import AvatarSpender from "../../../../components/AvatarSpender";
import styleCenterVertHorDiv from "../../utils/styleCenterVertHorDiv";
import { currentWoItemType, } from "../../workoutonomyTypes";
import { useEffect } from "react";
const successGreenColorHex = '#52c41a';

const { Text, Title } = Typography;

function onAvatarClick() {
    message.info("todo: onAvatarClick, open modal settings for the workout")
}

function onDotClick() {
    message.info("todo: onDotClick coming soon, toggler")
}

function DotsSets({ item }: { item: currentWoItemType }) {
    const sets = item.setsPlanned;
    let countDone = 0;

    let listOfDots = sets.map((el, i) => {
        const status = el.completed ? "success" : "default";
        const bgStyle = el.completed ? { backgroundColor: successGreenColorHex, } : { backgroundColor: "#00000040" };
        const essential = el.essential ? bgStyle : { backgroundColor: '#f472b6', }
        countDone++;
        return <Tooltip title={`${el.reps} reps`}>
            <div onClick={onDotClick} style={{ cursor: "pointer" }}>
                <Badge key={`init${countDone}`}
                    status={status}
                    count={el.reps}
                    style={essential}
                />
            </div>
        </Tooltip>;
    });

    return (<Space
        size={10}
        wrap>
        {listOfDots}
    </Space>)
}

function CardExercise({ item, onAddClick, onMinusClick, }: { item: currentWoItemType, onAddClick?: any, onMinusClick?: any }) {
    console.log("render");
    useEffect(() => {
        console.log("ccghhanged");
    }, [item])

    const countEssential = Number(item.setsPlanned.filter(el => el.essential).length);
    const countCompleted = Number(item.setsPlanned.filter(el => el.completed).length);
    return (<Card bodyStyle={{
        paddingTop: 5, paddingBottom: 12
    }} style={{ margin: 12, marginTop: 0, }}>
        <Row gutter={16}>
            <Col span={4} style={styleCenterVertHorDiv} >
                {/* <Avatar size={40} shape="square">???</Avatar> */}
                <Tooltip title={`${item.name} (${item?.tag?.title})`}>
                    <Badge dot color={item?.tag?.color}>
                        <AvatarSpender onClick={onAvatarClick} spenderName={item.name} size={40} shape="square" style={{ cursor: "pointer" }} />

                    </Badge>
                </Tooltip>

            </Col>

            <Col span={15} >
                <Title level={5} style={{ margin: 0 }}>{item.name}</Title>
                {item.shortInstructions && <>
                    <Text type="secondary">{item.shortInstructions}</Text>
                    <br /></>}
                <DotsSets item={item} />
                <br />

            </Col>
            <Col span={5}

                // style={{ display: 'flex', justifyContent: 'flex-end' }}
                style={styleCenterVertHorDiv}
            >
                <Space size={1}>
                    <Button type="link" icon={<MinusCircleOutlined />} onClick={onMinusClick} />
                    {/* <InputNumber /> */}
                    <span style={{ color: countCompleted >= countEssential ? "#005900" : undefined }}>{countCompleted}</span>
                    <Button type="link" icon={<PlusCircleOutlined />} onClick={onAddClick} />
                </Space>
            </Col>
        </Row>

    </Card >)
}
export default CardExercise;