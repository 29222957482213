import type { woDatType } from "../workoutonomyTypes";
import { Card, Row, Col, Typography, } from "antd";
import dayjs from "dayjs";
import StopwatchSeconds from "./StopwatchSeconds";
const { Title } = Typography;

function CardTopStats({ woDat }: { woDat: woDatType }) {
    const tsLastWorkout = dayjs.unix(woDat.lastWorkoutUnix); //7am GMT
    return (<Card>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                Time since last workout<br />
                {dayjs(tsLastWorkout).toString()}
                <Title style={{ margin: 0, }} level={4}><StopwatchSeconds tsLastWorkout={tsLastWorkout} /></Title>
            </Col>
        </Row>

    </Card>)
}
export default CardTopStats;