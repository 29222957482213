import { Typography, Space, Grid, } from "antd";
import { MehOutlined, LikeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const demoQueryString = "?trx=eyJiaWxsTmFtZSI6Ikx1bmNoIEJyZWFrIEZvb2QgT3JkZXIiLCJ0b3RhbEFmdGVyUHJvbW8iOjQ3NzAwLCJiaWxscyI6W3siaWQiOjEsIndobyI6IkRhbmllbCIsIml0ZW0iOiJDaGlja2VuIGFuZCBSaWNlIEJvd2wiLCJwcmljZUJlZm9yZVByb21vIjozNDUwMCwicHJpY2VBZnRlclByb21vIjoyMDY0OC4wNTUyMDcwMjYzNDd9LHsiaWQiOjIsIndobyI6IkphbWVzIiwiaXRlbSI6IkNoaWNrZW4gT25seSBCb3dsIiwicHJpY2VCZWZvcmVQcm9tbyI6NDUyMDAsInByaWNlQWZ0ZXJQcm9tbyI6MjcwNTEuOTQ0NzkyOTczNjV9XX0%3D";
const { useBreakpoint } = Grid;
function TagihanTitle() {
    const screens = useBreakpoint();

    const titleContents = <Space><MehOutlined />Tagihan Tanpa Drama</Space>;
    return <>
        <Link to="/" reloadDocument>
            <Typography.Title level={screens.xs ? 3 : undefined} style={{ color: "#ffffff", marginBottom: 0, textAlign: "center", }}>{titleContents}</Typography.Title>
        </Link>
        <Typography.Paragraph style={{ color: "#BCC1C6", marginTop: 12, textAlign: "center", }}>Feeling lost? <Typography.Link href={`${window.location.origin}${demoQueryString}`}>Try a demo</Typography.Link></Typography.Paragraph>
    </>
}
export default TagihanTitle;