import { Typography, Space, } from "antd";
import { GithubOutlined } from "@ant-design/icons";
function Footer() {
    return <>
        <Typography.Paragraph style={{ color: "#F4F9FF", marginTop: 12, textAlign: "center", }}>
            We're not here to judge. We're just here to help you get your money back.</Typography.Paragraph>
        <Typography.Paragraph style={{ color: "white", }}>
            <Typography.Link href="https://github.com/jamesadhitthana"><Space><GithubOutlined /> jamesadhitthana</Space></Typography.Link></Typography.Paragraph>
    </>
}
export default Footer;