import { Card, Typography, Row, Col, Badge, } from "antd";
import rupiah from "../../../utils/rupiah";
import CardTotalPrice from "./CardTotalPrice";
const { Title, Text } = Typography;


function RenderDiscountSummary({ totalAfterPromo, totalBeforePromo, }: { totalAfterPromo: number, totalBeforePromo: number }) {
    const totalSaved = totalBeforePromo - totalAfterPromo;
    const isSavingMoney = totalSaved >= 0;

    const RenderSavedPercentage = ({ }) => {
        const savedPercentage = 100 - Math.round((totalAfterPromo / totalBeforePromo) * 100);
        return (isFinite(savedPercentage) ? <>
            <br />
            <small><Badge status={isSavingMoney ? "success" : "error"} /> {`(${savedPercentage}% off)`}</small>
        </> : null);
    }

    return (<Text type={isSavingMoney ? "success" : "danger"}>
        <small>
            {isSavingMoney ? "Saved" : "Wasted"}: {rupiah((totalSaved))}
        </small>
        {/* <RenderSavedPercentage /> */}
    </Text>);
}

function CardTopIntro({ billName, totalAfterPromo, totalBeforePromo }: { billName?: string, totalAfterPromo: number, totalBeforePromo: number, }) {
    return (
        <Card bodyStyle={{
            padding: 8, paddingLeft: 12,
        }}>
            <Row>
                <Col span={10} style={{ marginTop: 12, }}>
                    <Text strong type="secondary">Invoice for</Text>
                    <Title level={5} style={{ margin: 0, marginTop: 2, marginBottom: 2, }}>{billName}</Title>
                    {/* <Text type="secondary"><small>
                        <s>
                            {rupiah(totalBeforePromo)}
                        </s>
                    </small></Text> */}
                    <RenderDiscountSummary totalAfterPromo={totalAfterPromo} totalBeforePromo={totalBeforePromo} />



                </Col>
                <Col span={14}>
                    <CardTotalPrice totalAfterPromo={totalAfterPromo} totalBeforePromo={totalBeforePromo} />
                </Col>
            </Row>

        </Card>)
}
export default CardTopIntro;