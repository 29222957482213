import React from 'react';
import { Card, Divider, List, Typography, Row, Col, Space, Button, Badge, Input, Segmented, InputNumber, Tooltip, } from 'antd';
import AvatarSpender from '../../components/AvatarSpender';
import rupiah from '../../utils/rupiah';
import { DeleteOutlined } from '@ant-design/icons';
import CardTotalPrice from './components/CardTotalPrice';
import CardTopIntro from './components/CardTopIntro';
import CardBillSummary from './components/CardBillSummary';
import type { anyObject, listOfBills } from '../../ttdTypes';
const { Text, Paragraph, Title, } = Typography;
const data = [
    { desc: 'Racing car sprays burning fuel into crowd.', },
    { desc: 'Japanese princess to wed commoner.', },
    { desc: 'Australian walks 100fffffffffffffffffffffffffffffffffkm after outback crash.', },
    { desc: 'Man charged over missing wedding girl.', },
    { desc: 'Los Angeles battles huge wildfires.', },
];


const inputRupiahSettings = {
    addonBefore: "IDR",
    style: { width: "100%" },
    formatter: (value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser: (value: any) => value!.replace(/\$\s?|(,*)/g, ''),
}

function MiddleRow({ bills }: { bills: listOfBills }) {
    return (<Row gutter={[16, 16]} style={{ justifyContent: "center", marginBottom: 12, }}>
        <Col span={22}  >
            <Card>
                <List itemLayout="vertical"
                    header={<div>Header</div>}
                    footer={<div>
                        Who	Item Name	Price Before Promo	Price After Promo
                        <br />  delete button
                        <br />    add item button
                        <br /> auto add row
                        <br />  total footer
                        <br /> total bill summary
                    </div>}
                    bordered
                    dataSource={bills}
                    renderItem={(it) => (
                        <List.Item actions={[<Tooltip title="Delete"><DeleteOutlined /></Tooltip>,]} >
                            <Space wrap>
                                <Col span={2}>
                                    <AvatarSpender spenderName={it.who} />
                                </Col>
                                <Col >
                                    <Row>
                                        <Text editable strong>{it.item}</Text>
                                    </Row>

                                    <Row>
                                        <Text editable type="secondary">{it.who}</Text>
                                    </Row>
                                    <Row>
                                        <Text type="success">you paid {rupiah(it.priceAfterPromo)}</Text>
                                    </Row>
                                </Col>
                                <Col sm={24}>
                                    <InputNumber placeholder="price before promo"
                                        value={it.priceBeforePromo} {...inputRupiahSettings}
                                    // onChange={(v) => onPriceChange(v, i)}
                                    />
                                </Col>

                            </Space>
                        </List.Item>
                    )}
                />
            </Card>
        </Col>
    </Row>)
}

function TagihanList({ bills, billName, totalAfterPromo, totalBeforePromo, }: { bills: listOfBills, billName?: string, totalAfterPromo: number, totalBeforePromo: number }) {
    return (
        <>
            <Row gutter={[16, 16]} style={{ justifyContent: "center", marginBottom: 12, }}>
                <Col span={22}  >
                    <CardTopIntro billName={billName} totalAfterPromo={totalAfterPromo} totalBeforePromo={totalBeforePromo} />
                </Col>
            </Row>

            <MiddleRow bills={bills} />

            <Row gutter={[16, 16]} style={{ justifyContent: "center", marginBottom: 12, }}>
                <Col span={22}  >
                    <CardBillSummary bills={bills} billName={billName} totalAfterPromo={totalAfterPromo} totalBeforePromo={totalBeforePromo} />
                </Col>
            </Row>


        </>
    );
}
export default TagihanList;