import { useEffect, useState } from "react";
import TagihanList from "../TagihanList/TagihanList";
import { useSearchParams, useLocation, } from "react-router-dom";
import { Card, Table, Input, InputNumber, Space, Row, Col, Avatar, Select, Form, Button, Tooltip, message, Typography, Divider, Grid, } from "antd";
import { DeleteOutlined, PlusCircleOutlined, WhatsAppOutlined, CheckOutlined, } from "@ant-design/icons"
import { omit, uniqueId, } from "lodash";
import short from "short-uuid";
import { useDebouncedCallback } from "use-debounce";
import thousandSeparator from "../../utils/thousandSeparator";
import LIST_OF_BILLS_SAMPLE from "./examples/sampleData";
import dayjs from "dayjs";
import type { ColumnsType } from 'antd/es/table';
import AvatarSpender from "../../components/AvatarSpender";
import rupiah from "../../utils/rupiah";
import { compress, decompress } from "compress-json";
const { useBreakpoint } = Grid;

type listOfBills = typeof LIST_OF_BILLS_SAMPLE;
type trxBase64 = { billName: string, totalAfterPromo: number, bills: listOfBills, };

const inputRupiahSettings = {
    addonBefore: "IDR",
    style: { width: "100%" },
    formatter: (value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser: (value: any) => value!.replace(/\$\s?|(,*)/g, ''),
}

function recalculateTotalBeforePromo(bills: listOfBills): number {
    return bills.reduce((acc, el) => {
        if (el.priceBeforePromo) return acc + el.priceBeforePromo;
        return acc;
    }, 0);
}




type processedDataBySpender = {
    [key: string]: {
        name: string;
        totalBeforePromo: number;
        totalAfterPromo: number;
        items: {
            name: string;
            who: string;
            priceBeforePromo: number | null;
            priceAfterPromo: number;
        }[];
    };
}


function buildWaMsg(name: string, itemList: { name: string, priceBeforePromo: number | null, priceAfterPromo: number }[], totalBeforePromo: number, totalAfterPromo: number, linkToApp: string, processedDataBySpender?: processedDataBySpender) {
    console.log('processed', processedDataBySpender)
    let dataBySpenderStr = '';
    if (processedDataBySpender) {
        const totalPerPerson: string[] = [];
        Object.keys(processedDataBySpender).map((keyName) => {
            const curr = processedDataBySpender[keyName];
            totalPerPerson.push(`*${curr.name}* : ~${rupiah(curr.totalBeforePromo)}~ ${rupiah(curr.totalAfterPromo)}`);
        })
        dataBySpenderStr = `--Total--\n${totalPerPerson.join('\n')}\n`;
    }

    return `*${name}*
--Items Bought (${itemList.length})--
${itemList.map(el => `${el.name} : ~${rupiah(el.priceBeforePromo || 0)}~ ${rupiah(el.priceAfterPromo)}`).join("\n")}
${dataBySpenderStr}--
You saved :~${rupiah(totalBeforePromo - totalAfterPromo)} (${100 - Math.round((totalAfterPromo / totalBeforePromo) * 100)}% discount)~
*Total to Pay* : ~${rupiah(totalBeforePromo)}~ ${rupiah(totalAfterPromo)}

--Transaction Link--

${linkToApp}
`;
}

function processDataBySpender(bills: listOfBills): processedDataBySpender {
    const processed = bills.reduce<{ [key: string]: { name: string, totalBeforePromo: number, totalAfterPromo: number, items: { name: string, who: string, priceBeforePromo: number | null, priceAfterPromo: number }[], } }>((acc, el) => {
        if (!el.who) return acc;
        if (!(el.who in acc)) acc[el.who] = { name: el.who, totalBeforePromo: 0, totalAfterPromo: 0, items: [], };
        acc[el.who].totalBeforePromo += el.priceBeforePromo || 0;
        acc[el.who].totalAfterPromo += el.priceAfterPromo;
        acc[el.who].items.push({ name: el.item, who: el.who, priceBeforePromo: el.priceBeforePromo, priceAfterPromo: el.priceAfterPromo });
        return acc;
    }, {});
    return processed;
}
function buildAllBillsWaMsg(billName: string, bills: listOfBills, totalBeforePromo: number, totalAfterPromo: number, linkToApp: string): string {
    const processed = processDataBySpender(bills);

    const itemsByOwner = Object.values(processed).reduce<any>((acc, el) => {
        const itemContents = el.items.map(el => {
            el.name = `${el.name} (${el.who})`;
            return el;
        })
        // acc.push([...acc, ...itemContents])
        return [...acc, ...itemContents];
    }, []);
    return buildWaMsg(`Bill${billName ? ` [${billName}]` : ""} (${dayjs().format("DD-MM-YYYY HH:mm:ss")})`, itemsByOwner, totalBeforePromo, totalAfterPromo, linkToApp, processed);
}


function TagihanSummaryBySpender({ billName, bills, screens, linkToApp }: { billName: string, bills: listOfBills, screens?: ReturnType<typeof Grid.useBreakpoint>, linkToApp: string }) {
    const processed = processDataBySpender(bills);

    const spenders = Object.values(processed).reduce<any[]>((acc, el, i) => {
        const curr = <Typography.Text key={`spender${i}`} copyable={{
            icon: <WhatsAppOutlined />,
            text: buildWaMsg(`Bill${billName ? ` [${billName}]` : ""} - ${el.name} (${dayjs().format("DD-MM-YYYY HH:mm:ss")})`, el.items, el.totalBeforePromo, el.totalAfterPromo, linkToApp),
            tooltips: ["Copy Whatsapp message", "Copied Whatsapp message"],
        }}>
            <AvatarSpender spenderName={el.name} /> <strong>{el.name}</strong> needs to pay {rupiah(el.totalAfterPromo)}</Typography.Text>
        acc.push(curr);
        return acc;
    }, []);
    return <Space
        // wrap={screens && screens.md ? true : false}
        // direction={screens && screens.md ? "horizontal" : "vertical"}
        // direction="vertical"
        wrap
        split={screens && screens.md ? <Divider type="vertical" /> : undefined}
    >{spenders}</Space>
}


function TagihanTable({ showMobile }: { showMobile: boolean }) {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const screens = useBreakpoint();
    const [form] = Form.useForm();
    const [bills, setBills] = useState<listOfBills>([]);
    const [nameOptions, setNameOptions] = useState<{ value: any, label: any, }[]>([]);
    // const [totalAfterPromo, setTotalPaid] = useState<number>(47700); //Total Paid (After Promo)

    useEffect(() => {
        console.log("UseEffect");
        // * Get trx (Total After Promo and bills[])
        const trx = searchParams.get("trx");
        console.log(`trx`, trx);
        let processedTrx: trxBase64 = { billName: "", totalAfterPromo: 0, bills: [] };
        try {
            if (trx) processedTrx = JSON.parse(atob(trx));
        } catch (err) {
            message.error("Oops, invalid transaction, please check if the link you opened is valid");
            return;
        }

        form.setFieldsValue({
            totalAfterPromo: processedTrx.totalAfterPromo,
            billName: processedTrx.billName,
        })
        const DATA_LOADED_FROM_SERVER = processedTrx.bills || [];//LIST_OF_BILLS_SAMPLE;
        refreshData(DATA_LOADED_FROM_SERVER);
    }, []);

    const debounceUrlSaver = useDebouncedCallback((bills: listOfBills) => {
        // * Clean up Object to save space
        const trx = {
            ...form.getFieldsValue(["billName", "totalAfterPromo"]),
            bills: bills.filter(el => Object.values(omit(el, ["id"])).some(x => x)),
        };
        // * Set Params
        searchParams.set("trx", btoa(JSON.stringify(trx)));
        setSearchParams(searchParams, { replace: true });
        console.log("***Debouncee!!!", trx);

        const compressedTrx = compress(trx)
        console.log("compressed", compressedTrx);
        console.log("uncompressed", decompress(compressedTrx));

        console.log("original base64", btoa(JSON.stringify(trx)));
        console.log("compressed base64", btoa(JSON.stringify(compressedTrx)));

        console.log("atob original base64", JSON.parse(atob(btoa(JSON.stringify(trx)))));
        console.log("atob compressed base64", decompress(JSON.parse(atob(btoa(JSON.stringify(compressedTrx))))));

    }, 500);

    const debounceUpdateBillName = useDebouncedCallback(() => {
        onSetBill(bills);
    }, 150);


    function refreshData(bills: listOfBills) {
        onSetBill(bills);
        onSetNameOptions(bills);
    }

    function onSetNameOptions(bills: listOfBills) {
        console.log("onSetNameOptions")
        const names = bills.map(el => ({ value: el.who, label: el.who, }));
        const uniqueNames = names.filter((v, i, a) => a.findIndex(v2 => (v2.label === v.label)) === i);
        setNameOptions(uniqueNames);
        return uniqueNames;

    }

    function lastItemInBillingListHasValue(bills: listOfBills) {
        return Object.values(omit(bills[bills.length - 1], ["id"])).some(el => el);
    }


    function onSetBill(currBills: listOfBills) {
        console.log("onSetBill");
        // GET TOTAL
        const totalAfterPromo = form.getFieldValue("totalAfterPromo");
        const totalBeforePromo = recalculateTotalBeforePromo(currBills);
        // calculate after prices
        const modified = currBills.reduce<listOfBills>((acc, el) => {
            el.priceAfterPromo = (totalAfterPromo / totalBeforePromo) * (el.priceBeforePromo || 0); //total paid/ total before promo * price before
            if (Number.isNaN(el.priceAfterPromo)) el.priceAfterPromo = 0;
            acc.push(el);
            return acc;
        }, []);

        // Push an empty list of bills object as a placeholder item only if the last item in the bill has some values in it
        if (lastItemInBillingListHasValue(modified)) {
            modified.push(generatePlaceholderBillingItem());
        }

        // Set Bills
        setBills(modified);
        form.setFieldsValue({ totalBeforePromo });
        // EXPERIMENTAL set up the querystring
        debounceUrlSaver(modified);
        return { bills: modified, totalBeforePromo }
    }

    function onPriceChange(v: any, i: number) {
        const currBills = [...bills];
        currBills[i].priceBeforePromo = v;
        onSetBill(currBills);
    }
    function onItemChange(e: any, i: number) {
        const currBills = [...bills];
        currBills[i].item = e.target.value;
        onSetBill(currBills);
    }
    function onWhoTagChanged(v: any, i: number) {
        const currBills = [...bills];
        currBills[i].who = v[0];
        onSetBill(currBills);
        onSetNameOptions(currBills);
        if (v.length > 1) message.warning("Only 1 person can be selected");
    }
    function deleteBillingItem(index: number) {
        const currBills = [...bills];
        currBills.splice(index, 1)
        onSetBill(currBills);
    }
    function generatePlaceholderBillingItem() {
        return { id: short.generate(), who: "", item: "", priceBeforePromo: null, priceAfterPromo: 0 };
    }
    function addNewRow() {
        const currBills = [...bills, generatePlaceholderBillingItem(),];
        onSetBill(currBills);
    }


    const COL_LIST: ColumnsType<any> = [
        {
            title: "Who", key: "who", dataIndex: "who",
            render: (v: string, r: any, i: any) =>
                <Space>
                    <AvatarSpender spenderName={v} />

                    <Select
                        mode="tags"
                        style={{ width: '100%', minWidth: 175, }}
                        placeholder="Person name"
                        value={v ? [v] : []}
                        onChange={(v) => onWhoTagChanged(v, i)}
                        options={nameOptions}
                    />
                </Space>

            ,
        },
        {
            title: "Item Name", key: "item", dataIndex: "item", responsive: ["md"],
            render: (v: string, r: any, i: any,) => <Input placeholder="item name" value={v} onChange={(v) => onItemChange(v, i)} />,
        },
        {
            title: "Price Before Promo", key: "priceBeforePromo", dataIndex: "priceBeforePromo", responsive: ["md"],
            // render: (v: string | number) => thousandSeparator(v),
            render: (v: any, r: any, i: any) =>
                <InputNumber placeholder="price before promo" value={v} {...inputRupiahSettings}
                    onChange={(v) => onPriceChange(v, i)}
                />,// <Input defaultValue={v} />,
        },
        {
            title: "Price After Promo", key: "priceAfterPromo", dataIndex: "priceAfterPromo", responsive: ["md"],
            render: (v: number) => <Tooltip title={thousandSeparator(v)} >{rupiah(v)}</Tooltip >,
        },
        {
            key: "actions",
            align: "right" as const,
            render: (v: any, r: any, i: any) => <Space>
                <Tooltip title="Delete"><Button onClick={() => deleteBillingItem(i)} type="link" icon={<DeleteOutlined />} /></Tooltip>
            </Space>,
        },
    ]

    const { totalAfterPromo, totalBeforePromo, billName, } = form.getFieldsValue(["totalAfterPromo", "totalBeforePromo", "billName",])
    return (<>
        {showMobile && <Row gutter={16}>
            <Col span={24}>
                <TagihanList totalAfterPromo={totalAfterPromo} totalBeforePromo={totalBeforePromo} billName={billName} bills={bills} />
            </Col>
        </Row>}

        <Card style={{ maxWidth: 1000 }}>
            <Space direction="vertical">
                <Row gutter={16}>
                    <Col span={24}>
                        <Form form={form}>
                            <Form.Item name="billName"  >
                                <Input size="large" placeholder="Bill Name (ex: Food with work Buddies)" onChange={debounceUpdateBillName} />
                            </Form.Item>
                            <Form.Item name="totalAfterPromo" label="Total Paid (After Promo)" initialValue={0} >
                                <InputNumber {...inputRupiahSettings} onChange={() => refreshData(bills)} />
                            </Form.Item>
                            <Form.Item name="totalBeforePromo" label="Total Before Promo" initialValue={0} >
                                <InputNumber disabled {...inputRupiahSettings} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>



                <Row gutter={16}>
                    <Col span={24}>
                        <Space direction="vertical">
                            <Table scroll={{ x: true }} rowKey={"id"} columns={COL_LIST} dataSource={bills}
                                footer={() => {
                                    // const { totalAfterPromo, totalBeforePromo, billName, } = form.getFieldsValue(["totalAfterPromo", "totalBeforePromo", "billName",])
                                    const savedPercentage = 100 - Math.round((totalAfterPromo / totalBeforePromo) * 100);
                                    const linkToApp = `${window.location.origin}${location.search}`;
                                    return <>
                                        <Space
                                            direction={screens.md ? "horizontal" : "vertical"}
                                            split={screens.md ? <Divider type="vertical" /> : undefined}
                                        >
                                            <Space>
                                                <strong>Total Bill:</strong>
                                                <s><Tooltip title={totalBeforePromo}>IDR {rupiah(totalBeforePromo)}</Tooltip></s>
                                                <Tooltip title={totalAfterPromo}>IDR {rupiah(totalAfterPromo)}</Tooltip>
                                            </Space>
                                            <Typography.Text>
                                                Total saved: <s>{rupiah(totalBeforePromo - totalAfterPromo)} {isFinite(savedPercentage) && `(${savedPercentage}% discount)`}</s>
                                            </Typography.Text>
                                            <Typography.Text>
                                                No Items Bought: {bills.filter(el => el.item || el.priceBeforePromo).length}
                                            </Typography.Text>
                                            <Typography.Text copyable={{
                                                text: buildAllBillsWaMsg(billName, bills, totalBeforePromo, totalAfterPromo, linkToApp),
                                                icon: [<><WhatsAppOutlined /> Copy Bill</>, <><CheckOutlined /> Copied message</>],
                                                tooltips: ["Copy Whatsapp message to bill your friends", "Copied Whatsapp message"]
                                            }} />
                                        </Space>
                                        <Divider />
                                        <TagihanSummaryBySpender billName={billName} bills={bills} screens={screens} linkToApp={linkToApp} />
                                    </>
                                }}
                                pagination={false}
                            />
                            <Button icon={<PlusCircleOutlined />} onClick={addNewRow} type="primary" block>Add new Item</Button>

                        </Space>
                    </Col>
                </Row>
            </Space>

        </Card >
    </>);
}
export default TagihanTable;