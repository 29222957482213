import React from 'react';
import { Routes, Route, } from "react-router-dom";
import NotFound from './components/NotFound';
import TagihanTable from "./features/TagihanTable/TagihanTable";
import TagihanTitle from "./TagihanTitle";
import TagihanList from "./features/TagihanList/TagihanList"
import Footer from "./features/Footer";
import WorkoutonomyIndex from "./features/Workoutonomy/index";
import './App.css';

function App() {
  return (
    <div >
      <Routes>
        <Route path="/" element={<div className="DalemanDiv">
          <TagihanTitle />
          <TagihanTable showMobile={false} />
          <Footer />
        </div>} />
        <Route path="/mobile" element={<div className="DalemanDiv">
          <TagihanTitle />
          <TagihanTable showMobile={true} />
          <Footer />
        </div>} />
        <Route path="workout" element={<WorkoutonomyIndex />} />
        <Route path='*' element={<NotFound />} />

      </Routes>
    </div>
  );
}

export default App;
