import { useState, } from "react";
import { Card, Typography, Badge, Segmented, Tooltip, } from "antd";
import { RedEnvelopeOutlined, PercentageOutlined, } from '@ant-design/icons';
import rupiah from "../../../utils/rupiah";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
const { Text, Title, Paragraph, } = Typography;
function CardTotalPrice({ totalAfterPromo, totalBeforePromo }: { totalAfterPromo: number, totalBeforePromo: number, }) {
    const [mode, setMode] = useState<string | number>("totalAfterPromo");

    const DATA_BY_MODE: { [key: string]: any } = {
        totalAfterPromo: {
            title: "Total Paid",
            val: totalAfterPromo,
        },
        totalBeforePromo: {
            title: "Bill",
            val: totalBeforePromo,
        },
    }

    return (<Card bodyStyle={{ backgroundColor: "#F6F8FD" }}>
        <Text strong type="secondary">{DATA_BY_MODE[mode].title}</Text>
        <Segmented style={{ float: "right", backgroundColor: "#DADBE0" }} size="small"
            value={mode} onChange={(v) => setMode(v)}
            options={[
                {
                    value: 'totalAfterPromo',
                    icon: <Tooltip title="Discounted Price (total after promo)"><PercentageOutlined /></Tooltip>,
                },
                {
                    value: 'totalBeforePromo',
                    icon: <Tooltip title="Original Bill (price before Promo)"><RedEnvelopeOutlined /></Tooltip>,
                },
            ]}
        />
        <Title level={5} style={{ margin: 0, marginTop: 2, marginBottom: 2, }}><span style={{ opacity: "45%" }}>Rp</span>{rupiah(DATA_BY_MODE[mode].val).replace("Rp", "")}</Title>
        <Text type="secondary"><Badge status="warning" /> <Tooltip placement="bottom" title={dayjs().format("LLLL")}>{dayjs().format("MMM D,YYYY")}</Tooltip></Text>

    </Card>)
}
export default CardTotalPrice;