import { Card, Button, Result } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function NotFound() {
    return <Card style={{ borderColor: "white" }}>
        <Helmet>
            <title>Not Found - Rapindocs</title>
            <meta name="description" content="Rapindocs could not find your page" />
        </Helmet>
        <Result
            status="404"
            title="404 - Page Not Found"
            subTitle="Oops, the page you visited does not exist."
            extra={<Link to="/"><Button type="primary">Back to TTD</Button></Link>}
        />
    </Card>
}