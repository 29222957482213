import dayjs from "dayjs";
import { useEffect, useState } from 'react';

const StopwatchSeconds = ({ tsLastWorkout }: { tsLastWorkout?: dayjs.Dayjs }) => {
    const [seconds, setSeconds] = useState(0);
    //
    useEffect(() => {
        console.log("Initializing Stopwatch useEffect");
        // * Inital setup for updating the seconds
        const secondsAfterLastWorkout = dayjs().diff(dayjs(tsLastWorkout), "seconds")
        setSeconds(secondsAfterLastWorkout); //initial setup for seconds

        // * Setup interval for auto update
        const stopwatch = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);
        return () => clearInterval(stopwatch);
    }, []);

    const formatStopwatch = (seconds: any) => {
        if (seconds >= 86400) return formatTimeWithYears(seconds);
        // Calculate minute and seconds
        const remainingSeconds = seconds % 60;
        const minutes = Math.floor((seconds % (60 * 60)) / 60);

        let timeStr = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        if (seconds >= 3600) { //append hour string if over 1 hour
            const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
            timeStr = `${hours.toString().padStart(2, '0')}:${timeStr}`;
        }
        return timeStr;
    };

    const formatTimeWithYears = (seconds: number) => {
        const remainingSeconds = seconds % 60;
        const minutes = Math.floor((seconds % (60 * 60)) / 60);
        let timeStr = `${minutes}m ${remainingSeconds}s`;
        if (seconds >= 3600) {
            const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
            timeStr = `${hours}h ${timeStr}`
            if (seconds >= 86400) { //more than 1 day
                const days = Math.floor((seconds % (60 * 60 * 24 * 365)) / (60 * 60 * 24));
                timeStr = `${days}d ${timeStr}`
                if (seconds >= 31536000) { //more than 1 year
                    const years = Math.floor(seconds / (60 * 60 * 24 * 365));
                    timeStr = `${years}y ${timeStr}`
                }
            }
        }
        return timeStr;
    };

    return (<>{formatStopwatch(seconds)}</>);
};
export default StopwatchSeconds;