import { useState, useEffect } from "react";
import { List, Card, Row, Col, Typography, Avatar, Button, Badge, } from "antd";
import { RightOutlined } from "@ant-design/icons";
import AvatarSpender from "../../../../components/AvatarSpender";
import CardWorkoutPlan from "../CardWorkoutPlan/CardWorkoutPlan";
import { sampleWorkoutHistoryData } from "../../examples/sampleWorkoutonomyData";
import { woHistoryType } from "../../workoutonomyTypes";
const { Text, Title, } = Typography;

function CompletedWorkouts() {
    const [dataSource, setDataSource] = useState<woHistoryType>([]);
    useEffect(() => {
        setDataSource(sampleWorkoutHistoryData);
    }, []);
    return (
        <List
            // grid={{gutter:16,xs:24,md:24}}
            dataSource={dataSource}
            renderItem={(item) => {
                return (<CardWorkoutPlan item={item} />)
            }} />)
}
export default CompletedWorkouts;